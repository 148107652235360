import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Confirm, Divider, Grid, Modal, Segment } from 'semantic-ui-react'
import helpers, { cancelToken } from '../../services/helpers'
import DetailsLine from '../common/DetailsLine'
import TransactionRiskIcon from '../common/TransactionRiskIcon'
import TransactionStatusIcon from '../common/TransactionStatusIcon'
import {useAuth0} from "../../react-auth0-wrapper";
import {fiatTokenInAmount, fiatTokenOutAmount, transactionTypeLabel} from "../../js/common";

const TransactionDetails = (props) => {
    const [
        txDetails,
        setTxDetails
    ] = useState({});
    const iconSize = "large";
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [messageModalConfirm, setMessageModalConfirm] = useState('');
    const [newStatus, setNewStatus] = useState('');
    const [userData, setUserData] = useState({});
    const { t } = useTranslation();
    const { getIdToken } = useAuth0();
    const {
        additionalData,
    } = props.data;
    const trx = props.data;
    const currency = trx.transaction_type.type === "token_to_fiat" ? trx.currency_code_out : trx.currency_code_in;
    const paymentDetails =  trx.payment;
    const payoutDetails =  trx.payout;
    const txID = trx.trx_uuid;

    useEffect(() => {
        if(props.metaData.role_name !== "PARTNER_ADMIN") {
            loadProfile();
        }
    }, []);

    const loadProfile = () => {
        if (trx.uuid == null) return;

        const token = getIdToken();
        const source = cancelToken.source();
        const promise = helpers.auth0
            .getProfile(token, trx.uuid, source)
            .then(response=>{
                setUserData(response.data.users);
            })
            .catch(console.error);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    const riskDetailsIcons = (val) => {
        if(!val) {
            return (<CheckCircleIcon fontSize="small" className="text-success" />);
        }
        return (<BlockIcon fontSize="small" className="text-danger" />);
    };

    const actionClickStatus = (statusData) => {
        setNewStatus(statusData);
        setMessageModalConfirm(t('transaction_change_status_confirm', {txID:txID, status:t('transaction_status_' + additionalData.status), newStatus:t('transaction_status_'+ statusData)}))
        setShowModalConfirm(true);
    };

    const handleConfirm = () => {
        const token = getIdToken();
        const source = cancelToken.source();
        // helpers.auth0.changeCashIoTransactionStatus(bfID, newStatus, token, source);
        props.setDataToast({ open: true, type: "info", message: t('transaction_change_status_success', {txID:txID, newStatus:t('transaction_status_'+ newStatus)}), timeAutoHide: 6000 });
        props.setShowModal(false);
        setShowModalConfirm(false);
    };

    const riskRelatedStuffShow = (type) => {
        return type && (type === 3 || type === 4);
    };

    const TransactionTypeInfo = () => {
        if(trx.transaction_type.type === "withdraw") {
            return (
                <>
                    <h1>Payout Details</h1>
                    <DetailsLine width={170}>
                        <span>Provider transaction ID</span>
                        <strong>{payoutDetails.provider_trx_id}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Account Number</span>
                        <strong>{payoutDetails.account_number}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>BIC/Swift Code</span>
                        <strong>{payoutDetails.bic_swift}</strong>
                    </DetailsLine>
                    <Divider section />
                </>
            )
        } else if(trx.transaction_type.type === "fiat_to_token") {
            return (
                <>
                    <h1>Payment Details</h1>
                    <DetailsLine width={170}>
                        <span>Status</span>
                        <strong>{paymentDetails.payment_status}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Type</span>
                        <strong>{paymentDetails.payment_type.type}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Provider</span>
                        <strong>{paymentDetails.payment_provider.name} ({paymentDetails.payment_provider.provider})</strong>
                    </DetailsLine>
                    <Divider section />
                </>
            )
        } else if(trx.transaction_type.type === "token_to_token") {
            return (
                <>
                    <h1>Swap Details</h1>
                    <DetailsLine width={170}>
                        <span>Token In</span>
                        <strong>{fiatTokenInAmount(trx)}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Token Out</span>
                        <strong>{(trx.token_out_amount * 1).toFixed(5)+' '+trx.token_out}</strong>
                    </DetailsLine>
                    <Divider section />
                </>
            )
        } else if(trx.transaction_type.type === "remittance") {
            return (
                <>
                    <h1>Payment Details</h1>
                    <DetailsLine width={170}>
                        <span>Status</span>
                        <strong>{paymentDetails.payment_status}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Type</span>
                        <strong>{paymentDetails.payment_type.type}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Provider</span>
                        <strong>{paymentDetails.payment_provider.name} ({paymentDetails.payment_provider.provider})</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Recipient Name</span>
                        <strong>{paymentDetails.recipient_first_name} {paymentDetails.recipient_last_name}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Recipient Clabe Number</span>
                        <strong>{paymentDetails.recipient_clabe}</strong>
                    </DetailsLine>
                    <Divider section />
                </>
            )
        } else {
            return (null);
        }
    };


    return (
        <React.Fragment>
            <Modal.Header>
                <Grid columns={3}>
                    <Grid.Row>
                        <Grid.Column>
                            {t('transaction_details_title')}
                        </Grid.Column>
                        <Grid.Column textAlign='center'>
                            {additionalData && (
                                <>
                                    {(additionalData.status === "in_progress" || additionalData.status === "needs_review") && (riskRelatedStuffShow(txDetails.type)) && (
                                        <>
                                            <ButtonGroup size="small" aria-label="small outlined button group">
                                                <Button onClick={() => actionClickStatus('approve')}>
                                                    <CheckCircleIcon fontSize="small" className="text-success">
                                                    </CheckCircleIcon>
                                                    {t('transaction_change_status_approve')}
                                                </Button>
                                                <Button onClick={() => actionClickStatus('cancel')}>
                                                    <BlockIcon fontSize="small" className="text-danger">
                                                    </BlockIcon>
                                                    {t('transaction_change_status_cancel')}
                                                </Button>
                                            </ButtonGroup>
                                        </>
                                    )}
                                </>
                            )}
                        </Grid.Column>
                        <Confirm
                            header={t('popup_header_confirm_status')}
                            open={showModalConfirm}
                            content={messageModalConfirm}
                            cancelButton={t('popup_cancel')}
                            confirmButton={t('popup_ok')}
                            onOpen={() => setShowModalConfirm(true)}
                            onCancel={() => setShowModalConfirm(false)}
                            onConfirm={handleConfirm}
                            size='tiny'
                        />
                        {riskRelatedStuffShow(txDetails.type) &&
                        <Grid.Column textAlign='right'>
                            <div style={{ fontSize: 14, fontWeight: 500, float: 'right' }}><TransactionRiskIcon iconSize={iconSize} risk={additionalData ? additionalData.risk : -1} /></div>
                            <div style={{ fontSize: 14, fontWeight: 500 }}><TransactionStatusIcon iconSize={iconSize} status={additionalData ? additionalData.status : null} /></div>
                        </Grid.Column>
                        }
                    </Grid.Row>
                </Grid>
                <div style={{ fontSize: 14, fontWeight: 500 }}>ID: {txID}</div>
            </Modal.Header>
            <Modal.Content scrolling>
                <Segment vertical>
                    <h1>Order Details</h1>
                    <DetailsLine width={170}>
                        <span>Transaction Status</span>
                        <strong>{trx.transaction_status}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>{t('transaction_details_data_and_time')}</span>
                        <strong>{moment(trx.created_at).utc().format('L LTS')}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Type</span>
                        <strong>{transactionTypeLabel(trx.transaction_type.type)}</strong>
                    </DetailsLine>
                    {trx.exchange &&
                        <DetailsLine width={170}>
                            <span>Exchange</span>
                            <strong>{trx.exchange.name}</strong>
                        </DetailsLine>
                    }
                    {trx.network &&
                        <DetailsLine width={170}>
                            <span>Network In</span>
                            <strong>{trx.network.name}</strong>
                        </DetailsLine>
                    }
                    {trx.network_out &&
                        <DetailsLine width={170}>
                            <span>Network Out</span>
                            <strong>{trx.network_out.name}</strong>
                        </DetailsLine>
                    }
                    <DetailsLine width={170}>
                        <span>Fiat/Token In Amount</span>
                        <strong>{fiatTokenInAmount(trx)}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Token/Fiat Out Amount</span>
                        <strong>{fiatTokenOutAmount(trx)}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Everest Fee</span>
                        <strong>{trx.ev_fee} {currency}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Gas Fee</span>
                        <strong>{trx.gas_fee} {currency}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Total Fee</span>
                        <strong>{trx.total_fee} {currency}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Wallet Address</span>
                        <strong>{trx.wallet_address}</strong>
                    </DetailsLine>

                    {trx.uuid &&
                        <div>
                            <Divider section />
                            <h1>User Details</h1>
                            <DetailsLine width={170}>
                                <span>Uuid</span>
                                <strong>{trx.uuid}</strong>
                            </DetailsLine>
                            {userData.firstName &&
                                <DetailsLine width={170}>
                                    <span>Name</span>
                                    <strong>{userData.firstName} {userData.lastName}</strong>
                                </DetailsLine>
                            }
                            {userData.phoneNumber &&
                                <DetailsLine width={170}>
                                    <span>Phone</span>
                                    <strong>+{userData.countryCode} {userData.phoneNumber}</strong>
                                </DetailsLine>
                            }
                        </div>
                    }

                    <Divider section />
                    <TransactionTypeInfo />
                    <h1>Risk Details</h1>
                    <DetailsLine width={170}>
                        <span>Risk Score</span>
                        <strong>{trx.risk_rating ? trx.risk_rating.risk_score : 'N/A'}</strong>
                    </DetailsLine>
                    <DetailsLine width={170}>
                        <span>Risk Level</span>
                        <strong>{trx.risk_rating ? trx.risk_rating.risk_level : 'N/A'}</strong>
                    </DetailsLine>
                    {
                        trx.risk_rating && trx.risk_rating.consequences.length > 0 &&
                        <table className="cashio-detail-table">
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                                <th>Score</th>
                                <th>Consider</th>
                                <th>Reject</th>
                            </tr>
                            {trx.risk_rating.consequences.map((con, i) =>
                                <tr key={i}>
                                    <td>{con.name || "-"}</td>
                                    <td>{con.value}</td>
                                    <td>{con.score}</td>
                                    <td>{riskDetailsIcons(con.consider)}</td>
                                    <td>{riskDetailsIcons(con.rejected)}</td>
                                </tr>
                            )}
                        </table>
                    }
                </Segment>
            </Modal.Content>
        </React.Fragment>
    )
}

export default TransactionDetails
