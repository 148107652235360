import moment from 'moment-timezone';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Button,
    Confirm,
    Dimmer,
    Divider,
    Dropdown,
    Grid,
    Icon,
    Image,
    Loader,
    Modal,
    Placeholder,
    Popup,
    Segment,
    Tab, Table, TabPane
} from 'semantic-ui-react';
import styled from 'styled-components';
import helpers, {cancelToken} from '../../services/helpers';
import DetailsLine from '../common/DetailsLine';
import ModalConfirm from '../common/ModalConfirm';
import {useAuth0} from '../../react-auth0-wrapper';
import MembersOrgSelect from './MembersOrgSelect';
import ModalKYCHistoric from '../common/ModalKYCHistoric';
import {withRouter} from 'react-router-dom';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

const Avatar = styled.div`
  background-image: url(data:image/png;base64,${props => props.photo});
  background-size: cover;
  background-position: center center;
  width: 140px;
  height: 140px;
  display: inline-block;
  vertical-align: top;
`;

const Thumb = styled.div`
  background-image: url(data:image/png;base64,${props => props.photo});
  background-size: cover;
  background-position: center center;
  width: 140px;
  height: 140px;
  display: inline-block;
  vertical-align: top;
  margin-right: 20px;
  margin-bottom: 20px;
`;

const CenterButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

const KYC = styled.div`
  margin-top: 40px;
`;

const isJSON = str => {
    try {
        return (JSON.parse(str) && !!str);
    } catch (e) {
        return false;
    }
}

async function openImage(token, address, filename, message, thumbnail, docType) {
    const newTab = window.open();
    if (docType === "identity") {
        const img = document.createElement('img');
        img.src = `data:image/png;base64,${thumbnail}`;
        newTab.document.body.appendChild(img);
    } else {
        const source = cancelToken.source();
        newTab.document.body.innerHTML = message;
        await helpers.auth0.previewDocument(token, address, filename, source).then((response) => {
            newTab.document.body.innerHTML = '';
            const img = document.createElement('img');
            img.src = `data:image/png;base64,${response.data.file}`;
            newTab.document.body.appendChild(img);
        });
    }
}

const MemberDetails = (props) => {
    const [documents, setDocuments] = useState([]);
    const [photoThumbnail, setPhotoThumbnail] = useState(null);
    const [allowPromote, setAllowPromote] = useState(true);
    const [organizacionSelected, setOrganizacionSelected] = useState("");
    const [filtered, setFiltered] = useState(props.metaData);
    const {t} = useTranslation();
    const {profile, wallets} = props.data;
    const activeOrg = window.ADMIN_SUB_ORGNAME;
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [messageModalConfirm, setMessageModalConfirm] = useState('');
    const [showModalConfirmKycStatus, setShowModalConfirmKycStatus] = useState(false);
    const [messageModalConfirmKycStatus, setMessageModalConfirmKycStatus] = useState('');
    const [showModalConfirmClearComplyAdvantage, setShowModalConfirmClearComplyAdvantage] = useState(false);
    const [messageModalConfirmClearComplyAdvantage, setMessageModalConfirmClearComplyAdvantage] = useState('');
    const [kycStatusToBe, setKycStatusToBe] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showOTPModalConfirm, setShowOTPModalConfirm] = useState(false);
    const [sanctionedWallets, setSanctionedWallets] = useState([]);
    const [walletScore, setWalletsScore] = useState([]);
    const [riskLevel, setRiskLevel] = useState("");
    const [staking, setStaking] = useState([]);
    const [isStakingLoading, setIsStakingLoading] = useState(false);

    const {getIdToken} = useAuth0();
    const [editPhone, setEditPhone] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(profile.phoneNumber);
    const [phoneCountryCode, setPhoneCountryCode] = useState(profile.countryCode);
    const [showPhoneModalConfirm, setShowPhoneModalConfirm] = useState(false);
    const [walletBalances, setWalletBalances] = useState([]);
    const [isWalletBalancesLoading, setIsWalletBalancesLoading] = useState(false);


    const KYC_REVIEW_STATUS = "NEED_REVIEW";
    const KYC_NOT_PROCESSED_STATUS = "NOT_PROCESSED";

    let reportKYC;
    if (isJSON(profile.watchlistReport)) {
        try {
            reportKYC = JSON.parse(profile.watchlistReport).breakdown;
        } catch (e) {
            reportKYC = false;
        }
    }

    const DocumentImages = (documents).map(({filename, thumbnail, docType}) => {
        const token = getIdToken();
        return (
            <Popup
                key={filename}
                trigger={
                    <Thumb style={{cursor: 'pointer'}}
                           className='thumb'
                           key={filename}
                           photo={thumbnail}
                           onClick={() => openImage(token, profile.uuid, filename, t('popup_loading'), thumbnail, docType)}
                    />
                }
                content={t('popup_open_in_new_window')}
                position='top center'
                inverted/>
        );
    });

    useEffect(() => {
        loadProfile();
        loadStakingDetails();
        loadDocuments();
        loadRiskRatingReport();
        loadSanctionedWalletList();
        loadWalletScores();
        loadWalletBalances();
    }, []);

    const loadRiskRatingReport = () => {
        const token = getIdToken();
        const source = cancelToken.source();
        const promise = helpers.auth0
            .getRiskRatingReport(profile.uuid, token, source)
            .then(response => {
                setRiskLevel(response.data.data.riskLevel);
            })
            .catch(console.error);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    const loadWalletScores = () => {
        const token = getIdToken();
        const source = cancelToken.source();
        const promise = helpers.auth0
            .getWalletsScore(profile.uuid, token, source)
            .then(response => {
                if (response.data.success && response.data.data) {
                    setWalletsScore(response.data.data);
                }
            })
            .catch(console.error);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    const loadSanctionedWalletList = () => {
        const token = getIdToken();
        const source = cancelToken.source();
        const promise = helpers.auth0
            .getSanctionedWallets(profile.uuid, token, source)
            .then(response => {
                setSanctionedWallets(response.data.data);
            })
            .catch(console.error);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    const loadProfile = () => {
        const token = getIdToken();
        const source = cancelToken.source();
        const promise = helpers.auth0
            .getProfile(token, profile.uuid, source)
            .then(response => {
                setPhotoThumbnail(response.data.photoThumbnail);
            })
            .catch(console.error);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    const loadDocuments = () => {
        const token = getIdToken();
        const source = cancelToken.source();
        const promise = helpers.auth0
            .getDocuments(token, profile.uuid, source)
            .then(resolveDocs)
            .catch(console.error);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    const loadStakingDetails = () => {
        const token = getIdToken();
        const source = cancelToken.source();
        setIsStakingLoading(true);
        const promise = helpers.auth0
            .getStakingDetails(token, profile.uuid, source)
            .then(response => {
                if (response.data.success) {
                    setStaking(response.data.data);
                    setIsStakingLoading(false);
                }
            })
            .catch((e) => {
                setIsStakingLoading(false);
                console.error(e)
            });
        return () => {
            helpers.cancel(promise, source);
        };
    }

    const loadWalletBalances = () => {
        // Get the token
        const token = getIdToken();

        const source = cancelToken.source();

        // Create an array of promises
        const promises = wallets.map(wallet => helpers.auth0.getWalletBalances(token, source, wallet.address));

        setIsWalletBalancesLoading(true);

        // Start all promises, but don't wait for them to resolve
        const results = Promise.all(promises);

        // Update the state with the new wallet balances
        results.then(resolvedResults => {
            resolvedResults.map(result => {
                if (result.data.success) {
                    setWalletBalances(prevWalletBalances => [...prevWalletBalances, ...result.data.data]);
                }
            });
            setIsWalletBalancesLoading(false);
        }).catch(error => {
            setIsWalletBalancesLoading(false);
            console.error(error);
        });

        return () => {
            helpers.cancel(results, source);
        };
    }

    const resolveDocs = (response) => {
        const documents = response.data.documents;
        if (documents) {
            setDocuments(documents);
            loadIdentityDocuments(documents);
        } else {
            loadIdentityDocuments();
        }
    };

    const loadIdentityDocuments = (docs) => {
        const token = getIdToken();
        const source = cancelToken.source();
        const promise = helpers.auth0
            .getIdentityDocuments(profile.uuid, token, source)
            .then(response => {
                resolveIdentityDocs(response, docs)
            })
            .catch(console.error);
        return () => {
            helpers.cancel(promise, source);
        };
    };

    const resolveIdentityDocs = (response, docs) => {
        const identityDocuments = response.data.data;
        if (!identityDocuments) {
            return;
        }

        const docsArr = identityDocuments.flatMap(doc => {
            const documentsToAdd = [];
            if (doc.front) {
                documentsToAdd.push({
                    filename: doc.docType.type,
                    thumbnail: doc.front,
                    docType: "identity"
                });
            }
            if (doc.back) {
                documentsToAdd.push({
                    filename: doc.docType.type,
                    thumbnail: doc.back,
                    docType: "identity"
                });
            }
            return documentsToAdd;
        });

        setDocuments(docs ? [...docs, ...docsArr] : docsArr);
    };

    const handleConfirmResolvePromoteToAgent = (response) => {
        return `${profile.firstName} ${profile.lastName} ${t('popup_promoted_to_agent')}`;
    };

    const handleConfirmResolveMemberRemovedFromOrg = (response) => {
        return `${profile.firstName} ${profile.lastName} has been removed from ${activeOrg}.`;
    };

    const capitalize = string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const getEverWallet = (wallets) => {
        if (!wallets) {
            return "";
        }

        for (const wallet of wallets) {
            if (wallet.name === "EverWallet") {
                return wallet.address;
            }
        }
        return "";
    }

    const statusIcon = status => {
        switch (status) {
            case 'consider':
                return (
                    <Icon color='yellow' name='exclamation triangle' style={{'marginRight': '10px'}}/>
                )
            case 'complete':
            case 'clear':
                return (
                    <Icon color='green' name='check circle' style={{'marginRight': '10px'}}/>
                )
            default:
                return (
                    <Icon color='blue' name='question circle' style={{'marginRight': '10px'}}/>
                )

        }
    }

    const handleConfirm = async () => {
        setIsLoading(true);
        const options = {
            memberAddressHex: getEverWallet(props.data.wallets),
            orgName: organizacionSelected,
        };
        helpers.registerOrg(options).then(response => {
            props.setDataToast({
                open: true,
                type: "info",
                message: `Member ${profile.firstName} ${profile.lastName} was sent to the organization ${organizacionSelected}`,
                timeAutoHide: 6000
            });
            props.setShowModal(false);
            setShowModalConfirm(false);
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
            props.setShowModal(false);
            props.setDataToast({open: true, type: "warning", message: err.response.data.Message, timeAutoHide: 6000});
            setShowModalConfirm(false);
        });
    };

    const openConfirmKycStatus = (status) => {
        setShowModalConfirmKycStatus(true);
        setKycStatusToBe(status);
        const msg = status === KYC_REVIEW_STATUS ?
            `Are you sure you want to mark ${profile.firstName} ${profile.lastName} as KYC member?` :
            `Are you sure you want to revert KYC status of ${profile.firstName} ${profile.lastName} to non-KYC?`;
        setMessageModalConfirmKycStatus(msg)
    };

    const clearComplyAdvantagePopup = () => {
        setShowModalConfirmClearComplyAdvantage(true);
        setMessageModalConfirmClearComplyAdvantage(`Are you sure you want to clear ComplyAdvantage status of ${profile.firstName} ${profile.lastName}?`);
    };

    const kycClear = () => {
        setIsLoading(true);
        const token = getIdToken();
        const source = cancelToken.source(); //profile.uuid,
        helpers.auth0.kycStatusChange(profile.uuid, kycStatusToBe, token, source).then(response => {
            setShowModalConfirmKycStatus(false);
            setIsLoading(false);
            if (!response.data.success) {
                window.alert("Something went wrong");
                return;
            }
            props.setDataToast({open: true, type: "info", message: "Success", timeAutoHide: 3000});
        }).catch(error => {
            setIsLoading(false);
            setShowModalConfirmKycStatus(false);
            const resp = JSON.parse(error.request.response);
            if (resp.code.message === "DOCUMENT_STATUS_NOT_IN_REVIEW") {
                window.alert("Document status are not in review. it means either user is already KYCed or didn't provide valid documents");
            } else {
                window.alert(resp.code.message);
            }
        });
    };

    const clearComplyAdvantage = async () => {
        setIsLoading(true);
        const token = getIdToken();
        const source = cancelToken.source();
        helpers.auth0.clearComplyAdvantageStatus(profile.uuid, token, source).then(response =>{
            if (!response.data.success) {
                window.alert("Something went wrong");
                return;
            }
            props.setDataToast({open: true, type: "info", message: "Success", timeAutoHide: 3000});
        }).catch(error =>{
            setIsLoading(false);
            setShowModalConfirmClearComplyAdvantage(false);
            const resp = JSON.parse(error.request.response);
            if (!resp.code){
                window.alert("Something went wrong");
                return;
            }
            window.alert(resp.code.message);
        });
    };

    const sendOTP = () => {
        setIsLoading(true);
        const token = getIdToken();
        const source = cancelToken.source();
        helpers.auth0.sendOTP(profile.uuid, token, source).then(response => {
            setShowOTPModalConfirm(false);
            setIsLoading(false);
            if (!response.data.success) {
                window.alert("Something went wrong");
                return;
            }
            props.setDataToast({open: true, type: "info", message: t('otp_send_suc'), timeAutoHide: 6000});
        }).catch(error => {
            setIsLoading(false);
            setShowOTPModalConfirm(false);
            const resp = JSON.parse(error.request.response);
            window.alert(resp.message);
        });
    }
    const handlePhoneNumber = (number, country) => {
        const countryCode = country.dialCode;
        const noCountryCodePhone = number.replace(countryCode, "");
        setPhoneNumber(noCountryCodePhone);
        setPhoneCountryCode(countryCode);
    };
    const updatePhoneNumber = () => {
        setIsLoading(true);
        const token = getIdToken();
        const source = cancelToken.source();
        const data = {
            countryCode: phoneCountryCode,
            phoneNumber: phoneNumber
        };
        helpers.auth0.updatePhone(profile.uuid, data, source, token).then(response => {
            console.log(response);
            setShowPhoneModalConfirm(false);
            setIsLoading(false);
            if (response.data.success) {
                props.setDataToast({open: true, type: "info", message: t('phone_update_suc'), timeAutoHide: 6000});
                setEditPhone(false);
            } else {
                window.alert("Something went wrong");
            }
        }).catch(error => {
            setIsLoading(false);
            setShowPhoneModalConfirm(false);
            const resp = JSON.parse(error.request.response);
            window.alert(resp.message);
        });
    };

    const ProfileDetails = (profile) => {
        return (
            <a>
                <Segment vertical>
                    <DetailsLine width={154}><span>E-mail</span><strong>{profile.email || 'N/A'}</strong></DetailsLine>
                    <DetailsLine
                        width={154}><span>{t('member_details_address')}</span><strong>{profile.address || '-'}</strong></DetailsLine>
                    <DetailsLine width={'auto'}
                                 style={{'display': 'flex', 'justifyContent': 'flex-start', 'alignItems': 'center'}}>
                        <span style={{width: '154px'}}>{t('member_details_phone_number')}</span>
                        {editPhone ?
                            <>
                                <PhoneInput
                                    containerStyle={{width: '285px'}}
                                    inputStyle={{width: '100%'}}
                                    country={'us'}
                                    disableCountryCode={false}
                                    countryCodeEditable={false}
                                    autoFormat={false}
                                    value={phoneCountryCode + phoneNumber}
                                    onChange={(number, country) => handlePhoneNumber(number, country)}
                                    onKeyDown={(e) => e.key === 'Enter' && setShowPhoneModalConfirm(true)}
                                />
                                <Button style={{backgroundColor: '#fa4261', color: 'white', marginLeft: '5px'}}
                                        onClick={() => setShowPhoneModalConfirm(true)}>
                                    {t('btn_update')}
                                </Button>

                                <Confirm
                                    header={t('popup_header_confirm_status')}
                                    open={showPhoneModalConfirm}
                                    content={t('phone_update_confirm')}
                                    cancelButton={t('popup_cancel')}
                                    confirmButton={t('popup_ok')}
                                    onOpen={() => setShowPhoneModalConfirm(true)}
                                    onCancel={() => setShowPhoneModalConfirm(false)}
                                    onConfirm={updatePhoneNumber}
                                    size='tiny'
                                />
                            </> :
                            <>
                                <strong>{phoneCountryCode && `(+${phoneCountryCode})`} {phoneNumber || 'N/A'}</strong>
                                <Icon onClick={() => setEditPhone(true)} color='blue' name='edit'
                                      style={{'marginLeft': '10px', 'marginTop': '-10px', 'cursor': 'pointer'}}/>
                            </>
                        }
                    </DetailsLine>
                </Segment>
                <Segment vertical>
                    <DetailsLine
                        width={154}><span>{t('member_details_sex')}</span><strong>{profile.gender}</strong></DetailsLine>
                    <DetailsLine
                        width={154}><span>{t('member_details_birth_date')}</span><strong>{profile.birthDate}</strong></DetailsLine>
                    <DetailsLine
                        width={154}><span>{t('member_details_nationality')}</span><strong>{profile.nationality}</strong></DetailsLine>
                </Segment>
            </a>
        )
    }

    const ProfileKycDetails = (profile) => {
        return (
            <a>
                <Segment basic>
                    <p><strong>{t('member_details_dataKYC')}</strong></p>
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column floated='left'>
                                <DetailsLine><span>{t('kyc_status_identity')}</span></DetailsLine>
                                <DetailsLine><span>{t('kyc_status_facial')}</span></DetailsLine>
                                <DetailsLine><span>{t('kyc_status_watchlist')}</span></DetailsLine>
                            </Grid.Column>
                            <Grid.Column textAlign='right'>
                                <DetailsLine><strong>{statusIcon(profile.identityStatus || 'N/A')} {capitalize(profile.identityStatus || 'N/A')} </strong></DetailsLine>
                                <DetailsLine><strong>{statusIcon(profile.facialStatus || 'N/A')} {capitalize(profile.facialStatus || 'N/A')} </strong></DetailsLine>
                                <DetailsLine><strong>{statusIcon(profile.watchlistStatus || 'N/A')} {capitalize(profile.watchlistStatus || 'N/A')} </strong></DetailsLine>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>

                <Segment vertical>
                    <p><strong>{t('kyc_report')}</strong>{!!reportKYC ? statusIcon('consider') : statusIcon('clear')}
                    </p>
                    {!!reportKYC ? (
                        <KYC>
                            <DetailsLine><span>Adverse Media:</span><strong>{statusIcon(reportKYC.adverse_media.result || 'N/A')}<span>{capitalize(reportKYC.adverse_media.result || 'N/A')}</span></strong></DetailsLine>
                            <DetailsLine><span>Sanction Lists:</span><strong>{statusIcon(reportKYC.sanction.result || 'N/A')}<span>{capitalize(reportKYC.sanction.result || 'N/A')}</span></strong></DetailsLine>
                            <DetailsLine><span>Politically Exposed Person:</span><strong>{statusIcon(reportKYC.politically_exposed_person.result || 'N/A')}<span>{capitalize(reportKYC.politically_exposed_person.result || 'N/A')}</span></strong></DetailsLine>
                        </KYC>
                    ) : <KYC>
                        <pre><a href={profile.watchlistReport}>{profile.watchlistReport}</a></pre>
                    </KYC>}
                </Segment>

                {sanctionedWallets.length > 0 &&
                    <>
                        <Segment basic>
                            <h3>Sanctioned Wallets:</h3>
                            {sanctionedWallets.map(sWallet =>
                                <>
                                    <DetailsLine><span>Wallet Address</span><strong>{sWallet.walletAddress}</strong></DetailsLine>
                                    <DetailsLine><span>Score</span><strong>{sWallet.score}</strong></DetailsLine>
                                    <DetailsLine><span>Details</span><strong><a href={sWallet.detailsURL}
                                                                                target="_blank">{sWallet.detailsURL}</a></strong></DetailsLine>
                                    <DetailsLine><span>Created</span><strong>{moment(sWallet.createdAt).utc().format('L LTS')}</strong></DetailsLine>
                                    <Divider section/>
                                </>
                            )}
                        </Segment>
                    </>
                }
                <Segment basic>
                    <p><u><strong>Additional User Data:</strong></u></p>
                    <DetailsLine><span>Country of Residence</span><strong>{profile.residentialAddress.country || 'N/A'}</strong></DetailsLine>
                    <DetailsLine><span>Nationality</span><strong>{profile.nationality || 'N/A'}</strong></DetailsLine>
                    <DetailsLine><span>Place of Birth</span><strong>{profile.birthPlace || 'N/A'}</strong></DetailsLine>
                    <DetailsLine><span>Employer</span><strong>{profile.employer || 'N/A'}</strong></DetailsLine>
                    <DetailsLine><span>Profession</span><strong>{profile.profession || 'N/A'}</strong></DetailsLine>
                    <DetailsLine><span>Occupation</span><strong>{profile.occupation || 'N/A'}</strong></DetailsLine>
                    <DetailsLine><span>User Declared PEP</span><strong>{profile.politicalExposure.isExposed ? profile.politicalExposure.exposed : 'NO'}</strong></DetailsLine>
                    <DetailsLine><span>Source of Wealth</span><strong>{profile.customerDueDiligence.source || 'N/A'}</strong></DetailsLine>
                    <DetailsLine><span>Origin of Funds</span><strong>{profile.customerDueDiligence.originFunds || 'N/A'}</strong></DetailsLine>
                    <DetailsLine><span>Expected Frequency</span><strong>{profile.customerDueDiligence.frequency || 'N/A'}</strong></DetailsLine>
                    <DetailsLine><span>Reason for Crypto</span><strong>{profile.customerDueDiligence.reason || 'N/A'}</strong></DetailsLine>
                </Segment>
            </a>
        )
    }

    const ProfileDocuments = (profile) => {
        return (
            <a>
                <Segment vertical>
                    <p><strong>{t('member_details_documents')}</strong></p>
                    {profile.identityDocuments && profile.identityDocuments.length > 0 ?
                        profile.identityDocuments.map(identityDoc =>
                            <>
                                <DetailsLine
                                    width={234}><span>{t('idv_type')}</span><strong>{identityDoc.documentType || 'N/A'}</strong></DetailsLine>
                                <DetailsLine
                                    width={234}><span>{t('idv_exp_date')}</span><strong>{identityDoc.documentDateOfExpiry}</strong></DetailsLine>
                                <hr align="left" style={{width: '50%', border: '1px solid #CCC'}}/>
                            </>
                        ) :
                        <>
                            <DetailsLine
                                width={234}><span>{t('idv_type')}</span><strong>{profile.documentType || 'N/A'}</strong></DetailsLine>
                            <DetailsLine
                                width={234}><span>{t('idv_exp_date')}</span><strong>{profile.documentDateOfExpiry}</strong></DetailsLine>
                        </>
                    }
                    {(DocumentImages.length > 0) ? (
                        <Image.Group size='small'>
                            {DocumentImages}
                        </Image.Group>
                    ) : (
                        <Placeholder style={{width: 140, height: 140, display: 'inline-block', verticalAlign: 'top'}}>
                            <Placeholder.Image/>
                        </Placeholder>
                    )}
                </Segment>
            </a>
        )
    }

    const ProfileWallets = (wallets) => {
        return (
            <>
                <Segment basic>
                    <h3>Linked/Associated Wallets:</h3>
                    {wallets.length > 0 && wallets.map(sWallet =>
                        <>
                            <DetailsLine><span>Wallet Name</span><strong>{sWallet.name}</strong></DetailsLine>
                            <DetailsLine><span>Wallet Address</span><strong>{sWallet.address}</strong></DetailsLine>
                            <DetailsLine><span>Score</span><strong>{sWallet.score}</strong></DetailsLine>
                            <Divider section/>
                        </>
                    )}
                </Segment>
            </>
        )
    }

    const StakingTab = (staking) => {
        return (
            <a>
                <Segment basic>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Days Left</Table.HeaderCell>
                                <Table.HeaderCell>Tokens Staked</Table.HeaderCell>
                                <Table.HeaderCell>Reward Tokens</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {staking != null && staking.length > 0 && staking.map((item, index) =>
                                <Table.Row colSpan='3' key={index}>
                                    <Table.Cell>{item.days_left}</Table.Cell>
                                    <Table.Cell>{item.tokens_staked}</Table.Cell>
                                    <Table.Cell>{item.reward_tokens}</Table.Cell>
                                </Table.Row>
                            )}
                            {staking == null &&
                                <Table.Row colSpan='3'><Table.Cell>No Ongoing Staking Rewards</Table.Cell></Table.Row>}
                        </Table.Body>
                    </Table>
                </Segment>
            </a>
        )
    }

    const PortfolioTab = (walletBalances) => {
        return (
            <a>
                <Segment basic>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>#</Table.HeaderCell>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Symbol</Table.HeaderCell>
                                <Table.HeaderCell>Network</Table.HeaderCell>
                                <Table.HeaderCell>Decimals</Table.HeaderCell>
                                <Table.HeaderCell>Balance</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {walletBalances != null && walletBalances.length > 0 && walletBalances.map((item, index) =>
                                <Table.Row colSpan='6' key={index}>
                                    <Table.Cell><img src={item.icon_uri} style={{height: "25px", width: "25px"}} /></Table.Cell>
                                    <Table.Cell>{item.name}</Table.Cell>
                                    <Table.Cell>{item.symbol}</Table.Cell>
                                    <Table.Cell>{item.network}</Table.Cell>
                                    <Table.Cell>{item.decimals}</Table.Cell>
                                    <Table.Cell>{item.fmt_balance}</Table.Cell>
                                </Table.Row>
                            )}
                            {walletBalances == null &&
                                <Table.Row colSpan='6'><Table.Cell>No Balances Available</Table.Cell></Table.Row>}
                        </Table.Body>
                    </Table>
                </Segment>
            </a>
        )
    }



    const panes = [
        {menuItem: 'Profile', render: () => <TabPane loading={isLoading}>{ProfileDetails(profile)}</TabPane>},
        {menuItem: 'KYC', render: () => <TabPane loading={isLoading}>{ProfileKycDetails(profile)}</TabPane>},
        {menuItem: 'Documents', render: () => <TabPane loading={isLoading}>{ProfileDocuments(profile)}</TabPane>},
        {menuItem: 'Wallets', render: () => <TabPane loading={isLoading}>{ProfileWallets(walletScore)}</TabPane>},
        {menuItem: 'Staking', render: () => <TabPane loading={isStakingLoading}>{StakingTab(staking)}</TabPane>},
        {menuItem: 'Portfolio', render: () => <TabPane loading={isWalletBalancesLoading}>{PortfolioTab(walletBalances)}</TabPane>},
    ]

    return (
        <>
            <Modal.Header>
                <span>{profile.firstName} {profile.lastName}</span>
            </Modal.Header>
            <Modal.Content scrolling>
                <Segment vertical style={{paddingTop: 0}}>
                    {photoThumbnail ? (
                        <Avatar photo={photoThumbnail}/>
                    ) : (
                        <Placeholder style={{width: 140, height: 140, display: 'inline-block', verticalAlign: 'top'}}>
                            <Placeholder.Image/>
                        </Placeholder>
                    )}
                    <div style={{display: 'inline-block'}}>
                        <Segment basic compact>
                            <DetailsLine><span>{t('member_details_created_at')}</span><strong>{moment(profile.createdAt).utc().format('L LTS')}</strong></DetailsLine>
                            <DetailsLine><span>{t('member_details_ew_address')}</span><strong>{getEverWallet(wallets)}</strong></DetailsLine>
                            <DetailsLine><span>{t('risk_rating')}</span><strong>{riskLevel}</strong></DetailsLine>
                        </Segment>
                    </div>
                </Segment>
                {/*<Segment vertical>*/}
                <Tab panes={panes}/>
                {/*</Segment>*/}
                {allowPromote === true ? (
                    <Segment vertical>
                        <CenterButtons>
                            <ModalConfirm
                                trigger={<Button positive>{t('member_details_promote')}</Button>}
                                onAction={() => helpers.promote(getEverWallet(props.data.wallets))}
                                onResolve={handleConfirmResolvePromoteToAgent}
                                onClose={() => {
                                    setAllowPromote(false);
                                    props.history.push("/agents");
                                }}
                            />
                            <Button style={{backgroundColor: '#fa4261', color: 'white', marginLeft: '5px'}}
                                    onClick={() => setShowOTPModalConfirm(true)}>{t('member_send_otp')}</Button>
                            <Confirm
                                header={t('popup_header_confirm_status')}
                                open={showOTPModalConfirm}
                                content={t('otp_send_confirm')}
                                cancelButton={t('popup_cancel')}
                                confirmButton={t('popup_ok')}
                                onOpen={() => setShowOTPModalConfirm(true)}
                                onCancel={() => setShowOTPModalConfirm(false)}
                                onConfirm={sendOTP}
                                size='tiny'
                            />
                            {/*{props.role_name === 'SUPER_ADMIN' && (*/}
                            {/*<>*/}
                            {/*<div className='ui input'>*/}
                            {/*<span>CRDT:</span><input type='text' style={{ width: 110}} placeholder="1 to 1000" autoComplete='off'*/}
                            {/*value={*/}
                            {/*crdtValue.crdt*/}
                            {/*}*/}
                            {/*onChange={e =>{setCrdtValue({crdt:e.target.value})}} */}
                            {/*/>*/}

                            {/*<Button style={{ backgroundColor: '#fa4261', color: 'white' }}*/}
                            {/*onClick={() => openConfirmAddCredits()}>*/}
                            {/*{t('member_details_btn_add_credits')}*/}
                            {/*</Button>*/}
                            {/*</div>*/}
                            {/**/}
                            {/*<Confirm*/}
                            {/*header={t('popup_header_confirm_status')}*/}
                            {/*open={showModalConfirmAddCredits}*/}
                            {/*content={messageModalConfirmAddCredits}*/}
                            {/*cancelButton={t('popup_cancel')}*/}
                            {/*confirmButton={t('popup_ok')}*/}
                            {/*onOpen={() => setShowModalConfirmAddCredits(true)}*/}
                            {/*onCancel={() => setShowModalConfirmAddCredits(false)}*/}
                            {/*onConfirm={handleConfirmAddCredits}*/}
                            {/*size='tiny'*/}
                            {/*/>*/}
                            {/*</>*/}
                            {/*)}*/}
                            {!props.OECPRE_MODE && activeOrg === 'INF' && (
                                <>
                                    <Dropdown
                                        className="selection color-letter-dropdown-white"
                                        text="Change to Organization"
                                        style={{backgroundColor: '#fa4261', color: 'white'}}>
                                        <Dropdown.Menu>
                                            <MembersOrgSelect
                                                onClick={evt => {
                                                    evt.stopPropagation()
                                                }}
                                                metaData={filtered}
                                                currentOrg={props.currentOrg}
                                                setCurrentOrg={props.setCurrentOrg}
                                                setMessageModalConfirm={setMessageModalConfirm}
                                                setShowModalConfirm={setShowModalConfirm}
                                                style={{backgroundColor: '#fa4261', color: 'white'}}
                                                setOrganizacionSelected={setOrganizacionSelected}
                                                data={props.data}
                                            />
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>
                            )}
                            {!!activeOrg && activeOrg !== 'INF' &&
                                <ModalConfirm
                                    trigger={<Button>Remove from {activeOrg}</Button>}
                                    onAction={() => helpers.removeMemberFromOrg(getEverWallet(props.data.wallets))}
                                    onResolve={handleConfirmResolveMemberRemovedFromOrg}
                                    onClose={() => setAllowPromote(false)}
                                />
                            }

                        </CenterButtons>
                    </Segment>
                ) : ('')}

                {(isLoading === true) ? (
                    <Dimmer active={true} page>
                        <Loader size='huge'>{t('popup_loading')}</Loader>
                    </Dimmer>
                ) : ""}

                <Segment vertical>
                    <CenterButtons>
                        <Button onClick={() => openConfirmKycStatus(KYC_REVIEW_STATUS)}>{t('member_details_kyc_check')}</Button>

                        <Button style={{backgroundColor: '#7dbcfa', color: 'white'}}
                                onClick={() => clearComplyAdvantagePopup()}>
                            {t('member_details_clear_comply_advantage')}
                        </Button>

                        <Button style={{backgroundColor: '#7dbcfa', color: 'white'}}
                                onClick={() => openConfirmKycStatus(KYC_NOT_PROCESSED_STATUS)}>
                            {t('member_details_kyc_uncheck')}
                        </Button>
                        {/*<ModalKYCHistoric idv={idv}/ report={profile.watchListReport || ""}/>*/}
                        <Confirm
                            header={t('popup_header_confirm_status')}
                            open={showModalConfirmKycStatus}
                            content={messageModalConfirmKycStatus}
                            cancelButton={t('popup_cancel')}
                            confirmButton={t('popup_ok')}
                            onOpen={() => setShowModalConfirmKycStatus(true)}
                            onCancel={() => setShowModalConfirmKycStatus(false)}
                            onConfirm={kycClear}
                            size='tiny'
                        />

                        <Confirm
                            header={t('popup_header_confirm_status')}
                            open={showModalConfirmClearComplyAdvantage}
                            content={messageModalConfirmClearComplyAdvantage}
                            cancelButton={t('popup_cancel')}
                            confirmButton={t('popup_ok')}
                            onOpen={() => setShowModalConfirmClearComplyAdvantage(true)}
                            onCancel={() => setShowModalConfirmClearComplyAdvantage(false)}
                            onConfirm={clearComplyAdvantage}
                            size='tiny'
                        />
                    </CenterButtons>
                </Segment>


                <Confirm
                    header={t('popup_header_confirm_status')}
                    open={showModalConfirm}
                    content={messageModalConfirm}
                    cancelButton={t('popup_cancel')}
                    confirmButton={t('popup_ok')}
                    onOpen={() => setShowModalConfirm(true)}
                    onCancel={() => setShowModalConfirm(false)}
                    onConfirm={handleConfirm}
                    size='tiny'
                />
            </Modal.Content>
        </>
    );
};

export default withRouter(MemberDetails);
