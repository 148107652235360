import axios from 'axios';
import { promiseStatus, PROMISE_PENDING } from 'promise-status-async';
import api from './api';
export const cancelToken = axios.CancelToken;
export const cancelMessage = 'Operation cancelled by the user.';

export const STATUS_INIT = 'INIT';
export const STATUS_LOADING = 'LOADING';
export const STATUS_NO_RESULTS = 'NO_RESULTS';
export const STATUS_OK = 'OK';
export const STATUS_ERROR = 'ERROR';

function jsonToQueryString(json) {
    if (!json) {
        return '';
    }

    return Object.keys(json).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`).join('&');
}
export default {
    async getCashIoTransactionDetails(id, source) {
        return await api.get(`/v3/admin/organization/${id}/cashio/txDetails`, null, { cancelToken: source.token });
    },
    async changeCashIoTransactionStatus(id, action) {
        return await api.post(`/v3/admin/tx/cashio`, { id, action });
    },
    async getTransactionDetails(id, source) {
        return await api.get(`/v3/admin/organization/${id}/txDetails`, null, { cancelToken: source.token });
    },
    async getTempTransactionDetails(id, source) {
        return await api.get(`/v3/mobile/everid/temporalRemittance/${id}/txDetails`, null, { cancelToken: source.token });
    },
    async postTransfer(dataTransfer) {
        return await api.post(`http://10.10.10.235:3000/api/processoutwardtt`,
            {
                "sremark": "KBLTT200125014",
                "sacaacno": "001110118517904",
                "saddr1": "LEVEL 3 KINA BANK HAUS",
                "saddr2": "DOUGLAS STREET",
                "saddr5": "PORT MORESBY",
                "sphoneno": "null71007821",
                "sendercntry": "PG",
                "ssource": "SALARY",
                "pursecd": "R13820",
                "indcd": "0000",
                "bfrel": "MYSELF",
                "bfcname": "SUMEET KUMAR",
                "bfcidtyp": "PASSPORTNO",
                "bfcidno": "1012382",
                "bfcaddr1": "Cairns Central",
                "bfcaddr2": "21 McLeod Street",
                "bfccity": "CAIRNS",
                "bfccountry": "AU",
                "bfcphoneno": "null0411369067",
                "bfcemail": "kumar.s.fj@gmail.com",
                "bfcacno": "113681658",
                "bswiftbic": "NATAAU3303M",
                "bfcbnknam": "NATIONAL AUSTRALIA BANK LIMITED",
                "bnkidentifier": "/084472",
                "bbaddr1": "Cairns Central",
                "bbaddr2": "1-21 McLeod Street",
                "bbcity": "CAIRNS",
                "bbcntry": "AU",
                "issfccd": "AUD",
                "trnfccd": "PGK",
                "convamt": 175.00,
                "trnamt": 75.53,
                "exchgrt": 0.4316
            }
        );
    },

    async getTransactionDetailsWithAddresses(data, source) {
        return await api.get(`/v3/admin/organization/txDetails?${jsonToQueryString(data)}`, null, { cancelToken: source.token });
    },

    async getOrganization(source, data) {
        return await api.get('/v1/admin/organization', data, { cancelToken: source.token });
    },

    async getList(url, options = { page: 1, limit: 10 }, source) {
        const { page, limit } = options;
        return await api.get(`${url}?page=${page}&limit=${limit}`, null, { cancelToken: source.token });
    },

    async cancel(listPromise, source) {
        if (await promiseStatus(listPromise) === PROMISE_PENDING) {
            source.cancel(cancelMessage);
        }
    },

    async search(url, options, source, data) {
        return await api.get(`${url}?${jsonToQueryString(options)}`, data, { cancelToken: source.token });
    },
    async searchTemp(url,options, source, data) {
        return await api.get(`${url}/${options}`, data, { cancelToken: source.token });
    },
    async updateIndex(url, data) {
        return await api.post(url, data);
    },

    async getDocument(account, filename) {
        return await api.get(`/v3/admin/organization/${account}/getDocument/${filename}`);
    },

    async getThumbnails(account, source) {
        return await api.get(`/v1/admin/organization/${account}/listDocument`, null, { cancelToken: source.token });
    },


    async checkKYC(uuid,scanReference,source) {
        return await api.get(`/v3/admin/jumio/check/${uuid}/${scanReference}`, null, { cancelToken: source.token});
    },

    async disburse() {
        return await api.post('/v1/admin/organization/disburse');
    },

    async createOrganizacion(dataOrg) {
        return await api.post('/v1/admin/organization', dataOrg);
    },

    async promote(account) {
        return await api.post(`/v3/admin/organization/agent/add`, { addressHex: account });
    },

    async degrade(account) {
        return await api.post(`/v3/admin/organization/downgradeAgent`, { addressHex: account });
    },

    async changeTransactionStatus(remittance, action) {
        return await api.post(`/v3/admin/tx`, { id: remittance, action: action });
    },

    async clearConsider(uuid) {
        return await api.put(`/v3/admin/${uuid}/KYC/clear`);
    },

    async availableReportTypes(source) {
        return await api.get(`/v3/admin/organization/availableReportTypes`, null, { cancelToken: source.token });
    },

    async generateReports(dataReport) {
        return await api.post(`/v3/admin/organization/generateReports`, dataReport);
    },

    async removeMemberFromOrg(account) {
        return await api.post(`/v3/admin/organization/member/remove`, { addressHex: account });
    },

    async removeAgentFromOrg(account) {
        return await api.post(`/v3/admin/organization/agent/remove`, { addressHex: account });
    },

    async registerOrg(options) {
        return await api.post('/v3/admin/organization/member/registerOrg', options);
    },

    async addCredits(options) {
        console.log("crdt",options);
        return await api.post('/v1/admin/organization/disburse', options);
    },

    async kinaTest(options) {
        return await api.post('/v3/admin/kinabank/test', options);
    },

    async kinaGeneral(endpoint, payload, options) {
        const data = JSON.stringify(payload);
        return await api.post('/v3/admin/kinabank' + endpoint, data, options);
    },

    async loadReport() {
        return await api.post(`http://192.168.15.245:9090/api/loadReport`, {
            "uuid": "test",
            "from": "2019-11-23 00:00:00",
            "to": "2019-12-01 23:59:59",
            "reportType": "kyc"
        });
    },

    async reportsFiltering(data, options) {
        return await api.post('/v3/admin/organization/search_report_index', data, options);
    },

    async createOrgProfile(source, data) {
        return await api.post(`/v3/admin/organization/profile/create`, data, {
            cancelToken: source.token
        });
    },
    auth0: {
        async getUser(token, source) {
            return api.auth0.get('/v3/admin/restricted/auth/users/current', {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },

        async getProfile(token,account, source) {
            return api.auth0.get(`/v3/admin/restricted/user/${account}/getProfile`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Credential-Identifier': window.ADMIN_SUB_ORGNAME
                },
                cancelToken: source.token
            });
        },

        async getStakingDetails(token,account, source) {
            return api.auth0.get(`/v3/admin/restricted/staking/user/${account}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Credential-Identifier': window.ADMIN_SUB_ORGNAME
                },
                cancelToken: source.token
            });
        },

        async getSanctionedWallets(uuid, token, source) {
            return api.auth0.get(`/v3/admin/restricted/kyc/${uuid}/wallets/sanctioned`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Credential-Identifier': window.ADMIN_SUB_ORGNAME
                },
                cancelToken: source.token
            });
        },

        async getWalletsScore(uuid, token, source) {
            return api.auth0.get(`/v3/admin/restricted/${uuid}/wallets/score`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Credential-Identifier': window.ADMIN_SUB_ORGNAME
                },
                cancelToken: source.token
            });
        },

        async getRiskRatingDetails(id, token, source) {
            return api.auth0.get(`/v3/admin/restricted/risk/transaction/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Credential-Identifier': window.ADMIN_SUB_ORGNAME
                },
                cancelToken: source.token
            });
        },

        async getCashIoTransactionDetails(id, token, source) {
            return api.auth0.get(`/v3/admin/restricted/organization/${id}/cashio/txDetails`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },

        async changeCashIoTransactionStatus(id, action, token, source) {
            return api.auth0.post(`/v3/admin/restricted/organization/tx/cashio`, {id, action}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },

        async getRiskRatingReport(uuid, token, source) {
            return api.auth0.get(`/v3/admin/restricted/risk/${uuid}/user`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },

        async getDocuments(token, account, source) {
            return api.auth0.get(`/v3/admin/restricted/${account}/listDocument`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },

        async previewDocument(token, account, filename, source) {
            return api.auth0.get(`/v3/admin/restricted/${account}/getDocument/${filename}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },


        async getUsers(token, source) {
            return api.auth0.get('/v3/admin/restricted/organization/auth/usersList', {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },

        async kycClear(uuid, token, source) {
            return api.auth0.post(`/v3/admin/restricted/kyc/${uuid}/clear-status`, null, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },

        async kycStatusChange(uuid, status, token, source) {
            return api.auth0.post(`/v3/admin/restricted/kyc/${uuid}/${status}/status`, null, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },

        async clearComplyAdvantageStatus(uuid, token, source) {
            return api.auth0.post(`/v3/admin/restricted/kyc/${uuid}/clear/comply-advantage`, null, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },

        async getIdentityDocuments(uuid, token, source) {
            return api.auth0.get(`/v3/admin/restricted/kyc/${uuid}/documents`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },

        async getRoles(token, source) {
            return api.auth0.get('/v3/admin/restricted/organization/auth/rolesList', {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },

        async getMembers(token, source, keyword) {
            let url = "/v3/admin/restricted/organization/search_index";
            if(keyword) {
                url += "?keyword="+keyword;
            }
            return api.auth0.get(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Credential-Identifier': window.ADMIN_SUB_ORGNAME
                },
                cancelToken: source.token
            });
        },

        async sendOTP(uuid, token, source) {
            return api.auth0.post(`/v3/admin/restricted/otpAuth/new`, {uuid}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },

        async refCodeList(token, source) {
            return api.auth0.get(`/v3/admin/restricted/referral/codes`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Credential-Identifier': window.ADMIN_SUB_ORGNAME
                },
                cancelToken: source.token
            });
        },

        async createRefCode(data, token, source) {
            return api.auth0.post(`/v3/admin/restricted/referral/create`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Credential-Identifier': window.ADMIN_SUB_ORGNAME
                },
                cancelToken: source.token
            });
        },

        async updateRefCode(data, token, source) {
            return api.auth0.put(`/v3/admin/restricted/referral`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Credential-Identifier': window.ADMIN_SUB_ORGNAME
                },
                cancelToken: source.token
            });
        },

        async getRefCodeUseCount(code, token, source) {
            return api.auth0.get(`/v3/admin/restricted/referral/${code}/refcount`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Credential-Identifier': window.ADMIN_SUB_ORGNAME
                },
                cancelToken: source.token
            });
        },

        async getOrgProfiles(token, source) {
            return api.auth0.get(`/v3/admin/restricted/organization/profiles`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Credential-Identifier': window.ADMIN_SUB_ORGNAME
                },
                cancelToken: source.token
            });
        },

        async updateOrgProfile(token, source, data) {
            return api.auth0.put(`/v3/admin/restricted/organization/profile/update`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Credential-Identifier': window.ADMIN_SUB_ORGNAME
                },
                cancelToken: source.token
            });
        },

        async updateOrgProfileFile(token, source, data) {
            return api.auth0.put(`/v3/admin/restricted/organization/profile/update/file`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Credential-Identifier': window.ADMIN_SUB_ORGNAME
                },
                cancelToken: source.token
            });
        },

        async search(url, options, source, token) {
            return api.auth0.get(`${url}?${jsonToQueryString(options)}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Credential-Identifier': window.ADMIN_SUB_ORGNAME
                }, cancelToken: source.token
            });
        },

        async updatePhone(uuid, data, source, token) {
            return api.auth0.put(`/v3/admin/restricted/user/${uuid}/phone`, data, {
                headers: {'Authorization': `Bearer ${token}`},
                cancelToken: source.token
            });
        },

        async getInfOrganizations(token, source) {
            return api.auth0.get(`/v3/admin/restricted/organizations?limit=1000`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-Credential-Identifier': "INF"
                },
                cancelToken: source.token
            });
        },

        async getUniqueAppKey(token, source) {
            return api.auth0.get(`/v3/admin/restricted/partner/appKey`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },

        async createPartner(token, source, data) {
            return api.auth0.post(`/v3/admin/restricted/partner`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },

        async updatePartner(token, source, data, id) {
            return api.auth0.put(`/v3/admin/restricted/partner/${id}`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },

        async deletePartner(token, source, id) {
            return api.auth0.delete(`/v3/admin/restricted/partner/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },

        async partnerUserCount(token, source) {
            return api.auth0.get(`/v3/admin/restricted/partner/user/count`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },

        async partnerUserSearch(token, source, walletAddress) {
            return api.auth0.get(`/v3/admin/restricted/partner/user/${walletAddress}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token
            });
        },

        async getWalletBalances(token, source, walletAddress) {
            return api.auth0.get(`/v3/admin/restricted/user/wallet/${walletAddress}`, {
                headers: {
                    'Authorization': `Bearer ${token}`

                },
                cancelToken: source.token
            });
        }
    },
};
