import { sortBy } from 'lodash';
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Modal, Message, Pagination, Table, Grid, Segment, Loader } from 'semantic-ui-react';
import helpers, { cancelToken, STATUS_INIT, STATUS_LOADING, STATUS_NO_RESULTS, STATUS_ERROR, STATUS_OK } from '../../services/helpers';
import ShowResultsContext from '../../contexts/ShowResultsContext';
import SearchTermContext from '../../contexts/SearchTermContext';
import MembersContext from '../../contexts/MembersContext';
import PageContext from '../../contexts/PageContext';
import MemberDetails from './MemberDetails';
import PageSelect from '../common/PageSelect';
import ErrorMessage from '../common/ErrorMessage';
import {useAuth0} from "../../react-auth0-wrapper";


const MembersList = (props) => {
    const [selectedMember, setSelectedMember] = useState(null);
    const [column, setColumn] = useState(props.sortByColumn);
    const [direction, setDirection] = useState(props.sortDirection);
    const [showModal, setShowModal] = useState(false);
    const [listPromise, setListPromise] = useState({ promise: null, source: null });

    const setMembers = useContext(MembersContext);
    const { searchTerm } = useContext(SearchTermContext);
    const { itemsPerPage } = useContext(ShowResultsContext);
    const { currentPage, setCurrentPage } = useContext(PageContext);
    const { getIdToken } = useAuth0();

    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            if (listPromise.source !== null) {
                helpers.cancel(listPromise.promise, listPromise.source);
            }
        }
    }, [listPromise]);

    if (props.status === STATUS_INIT) {
        return (
            <Segment>
                <Loader active size='large' inline='centered'>{t('list_message_loading_members')}</Loader>
            </Segment>
        );
    }

    if (props.status === STATUS_ERROR) {
        return <ErrorMessage error={props.error} />;
    }

    if (props.status === STATUS_NO_RESULTS) {
        return (
            <Message>
                <Message.Header>{t('list_message_no_results_title')}</Message.Header>
                <p>{t('list_message_no_results_text')}</p>
            </Message>);
    }

    const loadingContent = (
        <Table.Row>
            <Table.Cell colSpan='8'>
                <Segment basic>
                    <Loader active size='large' inline='centered'>{t('list_message_loading_members')}</Loader>
                </Segment>
            </Table.Cell>
        </Table.Row>
    );


    // pagination
    const pageNumbers = [];
    for (let x = 0; x < Math.ceil(props.totalCount / itemsPerPage); x++) {
        pageNumbers.push(x);
    }

    const resolved = (response) => {
        const { memberList, memberCount, success } = response.data;
        if (success === true) {
            setListPromise({ promise: null, source: null });
            setMembers({
                list: memberList,
                count: memberCount,
                status: (memberList && memberList.length && memberCount > 0) ? STATUS_OK : STATUS_NO_RESULTS
            });
        }
    };

    const handlePaginationChange = (event, { activePage }) => {
        setCurrentPage(activePage);
        setMembers({
            list: -1,
            count: props.totalCount,
            status: STATUS_LOADING
        });

        if (listPromise.source !== null) {
            helpers.cancel(listPromise.promise, listPromise.source);
        }

        const options = { keyword: `${searchTerm}`, page: activePage, limit: itemsPerPage, sort: 'createdAt' };
        const source = cancelToken.source();
        const promise = helpers.auth0
            .search(props.listURL, options, source, getIdToken())
            .then(resolved)
            .catch(console.error);
        setListPromise({ promise, source });
    };


    // column sort
    const sortByColumn = (clickedColumn) => () => {
        if (column !== clickedColumn) {
            setColumn(clickedColumn);
            setDirection('ascending');
            return;
        }
        setDirection(direction === 'ascending' ? 'descending' : 'ascending');
    };

    function handleSort(list, clickedColumn) {
        const sortList = sortBy(list, (obj) => {
            switch (clickedColumn) {
                case 'address':
                case 'balance':
                    return obj.wallet[clickedColumn] && obj.wallet[clickedColumn].toLowerCase();
                default:
                    return obj.profile[clickedColumn] && obj.profile[clickedColumn].toLowerCase();
            }
        });

        if (direction === 'descending') {
            return sortList.reverse();
        }
        return sortList;
    };

     
    // modal: member details
    const onMemberInfoClick = (event) => {
        event.preventDefault();
        if (event.target.classList.contains('user')) {
            const key = event.target.dataset.key.toLowerCase();
            const selectedMemberData = props.list.filter(member => {
                return member.profile.uuid === key;
            });
            setSelectedMember(selectedMemberData[0]);
            setShowModal(true);
        }
    };

    const onCloseModal = () => {
        setShowModal(false);
        setSelectedMember(null);
    };

    const getEverWallet = (wallets) => {
        if (!wallets) {
            return "";
        }

        for (const wallet of wallets) {
            if (wallet.name === "EverWallet") {
                return wallet.address;
            }
        }
        return "";
    }

    const tableRows = handleSort(props.list, column).map(({ profile, wallets }) => {
        return (
            <Table.Row key={profile.uuid}>
                <Table.Cell>{profile.firstName}</Table.Cell>
                <Table.Cell>{profile.lastName}</Table.Cell>
                <Table.Cell>{profile.birthDate}</Table.Cell>
                <Table.Cell>
                    {getEverWallet(wallets)}
                </Table.Cell>
                <Table.Cell textAlign='center'>
                    <Icon circular inverted name='user' color='blue' data-key={profile.uuid} className='icon-button' />
                </Table.Cell>
            </Table.Row>
        );
    });

    return (
        <Grid padded='vertically'>
            <Grid.Column>
                <Table sortable celled singleLine compact onClick={onMemberInfoClick}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                style={{ width: 200 }}
                                sorted={column === 'firstName' ? direction : null}
                                onClick={sortByColumn('firstName')}>
                                {t('member_list_first_name')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                style={{ width: 200 }}
                                sorted={column === 'lastName' ? direction : null}
                                onClick={sortByColumn('lastName')}>
                                {t('member_list_last_name')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                style={{ width: 130 }}
                                sorted={column === 'birthDate' ? direction : null}
                                onClick={sortByColumn('birthDate')}>
                                {t('member_list_birth_date')}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={column === 'address' ? direction : null}
                                onClick={sortByColumn('address')}>
                                {t('member_list_ew_address')}
                            </Table.HeaderCell>
                            <Table.HeaderCell width={1}>{t('member_list_details')}</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {(props.status === STATUS_LOADING) ? loadingContent : tableRows}
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='6' textAlign='center' style={{ position: 'relative' }}>
                                <Pagination
                                    activePage={currentPage}
                                    totalPages={pageNumbers.length}
                                    onPageChange={handlePaginationChange}
                                    siblingRange={1}
                                    boundaryRange={1}
                                    ellipsisItem={undefined}
                                    firstItem={undefined}
                                    lastItem={undefined}
                                    prevItem={undefined}
                                    nextItem={undefined}
                                    disabled={pageNumbers.length === 1}
                                />
                                <PageSelect activePage={currentPage}
                                    pagesTotal={pageNumbers.length}
                                    onPageChange={handlePaginationChange}
                                />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                <Modal open={showModal} onClose={onCloseModal}>
                    <MemberDetails data={selectedMember}
                        currentOrg={props.currentOrg}
                        setCurrentOrg={props.setCurrentOrg}
                        metaData={props.metaData}
                        setShowModal={setShowModal}
                        setDataToast={props.setDataToast}
                        role_name={props.role_name}/>
                </Modal>
            </Grid.Column>
        </Grid>
    );
};

export default MembersList;
